import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import notfound from "../assets/imagesf3.png";

const StartFile = () => {
  return (
    <Container>
      <Wrapper>
        <NotFound src={notfound} />
        <Message>
          <span>Page Not Found</span>
          <br />
          The Page you are looking for:
          <br /> has either been removed or does not exist.
          <br /> Try checking the url or try reloading the web page. Or
          <Button to="/"> go back to home page</Button>
        </Message>
      </Wrapper>
    </Container>
  );
};

export default StartFile;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
`;
const Wrapper = styled.div`
  width: 70%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;
const NotFound = styled.img`
  width: 900px;
  height: 550px;
  object-fit: cover;
  position: absolute;
  top: 100px;
  left: 400px;
`;
const Message = styled.div`
  text-align: right;
  font-weight: 10px;
  margin-right: 50px;
  margin-bottom: 30px;
  font-family: cursive;
  font-size: 21px;
  line-height: 35px;
  color: #969696;
  z-index: 1;
  span {
    font-weight: bold;
    font-size: xx-large;
    color: #808080;
  }
`;
const Button = styled(NavLink)``;
const Head = styled.div``;

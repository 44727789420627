import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./Components/AboutPage/About";
import Business from "./Components/BusinessPage/Buisiness";
import ErrorPage from "./Components/ErrorPage/ErrorPage";
import Footer from "./Components/Footer";
import ForEngineers from "./Components/ForEngineersPage/ForEngineers";
import Header from "./Components/Header";
import StartFile from "./Components/StartFile";

function App() {
  return (
    <BrowserRouter>
      <Header />

      <Routes>
        <Route path="/" element={<StartFile />} />
        <Route path="/forengineers" element={<ForEngineers />} />
        <Route path="/business" element={<Business />} />
        <Route path="/enterprise" element={<StartFile />} />
        <Route path="/*" element={<ErrorPage />} />
        <Route path="/about" element={<About />} />
      </Routes>

      <Footer />
    </BrowserRouter>
  );
}

export default App;

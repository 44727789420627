import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import logo from "./assets/logo.svg";
import logo2 from "./assets/logo2.svg";
import GlobalButton from "./GlobalButton";

const Header = () => {
  const [show, setShow] = React.useState(false);

  const changeHeaderColor = () => {
    if (window.scrollY >= 1) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  window.addEventListener("scroll", changeHeaderColor);
  return (
    <>
      {show ? (
        <div>
          <Container bc="white" cl="black">
            <Logo>victor</Logo>
            <Navigation>
              <Nav to="/forengineers">For Technology Experts</Nav>
              <Nav to="/business">Business</Nav>
              <Nav to="/enterprise">Enterprise</Nav>
              <Nav to="/about">About Us</Nav>
            </Navigation>
            <ButtonHold>
              <GlobalButton
                width="240px"
                height="50px"
                color="black"
                backgroundColor=""
                border="1px solid black"
                borderRadius="30px"
                content="Apply for Jobs"></GlobalButton>
              <GlobalButton
                width="240px"
                height="50px"
                color="black"
                backgroundColor="#56c870"
                border=""
                borderRadius="30px"
                content="Hire Talents"></GlobalButton>
            </ButtonHold>
          </Container>
        </div>
      ) : (
        <div>
          <Container bc="" cl="white">
            <Logo>victor</Logo>
            <Navigation>
              <Nav to="/forengineers">For Technology Experts</Nav>
              <Nav to="/business">Business</Nav>
              <Nav to="/enterprise">Enterprise</Nav>
              <Nav to="/about">About Us</Nav>
            </Navigation>
            <ButtonHold>
              <GlobalButton
                width="240px"
                height="50px"
                color="white"
                backgroundColor=""
                border="1px solid white"
                borderRadius="30px"
                content="Apply for Jobs"></GlobalButton>
              <GlobalButton
                width="240px"
                height="50px"
                color="black"
                backgroundColor="#56c870"
                border=""
                borderRadius="30px"
                content="Hire Talents"></GlobalButton>
            </ButtonHold>
          </Container>
        </div>
      )}
    </>
  );
};

export default Header;

const Container = styled.div<{ bc: string; cl: string }>`
  width: 100%;
  height: 80px;
  display: flex;
  background-color: ${(props) => props.bc};
  color: ${(props) => props.cl};
  align-items: center;
  z-index: 99;
  position: fixed;
  top: 0px;
  transition: all 350ms;
`;
const Logo = styled.div`
  height: 73px;
  margin-left: 100px;
  margin-right: 100px;
  font-size: xx-large;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-family: cursive;
`;
const Navigation = styled.div`
  display: flex;
`;
const Nav = styled(NavLink)`
  font-size: x-large;
  margin-right: 30px;
  margin-left: 30px;
  cursor: pointer;
  text-decoration: none;
  color: #a7a7a7;
`;
const ButtonHold = styled.div`
  display: flex;
`;
